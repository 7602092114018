import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Imprint = (props) => (
    <Layout>
        <Helmet>
            <title>NEA Xplore</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="imprint">
                <div className="inner">
                    <header className="major">
                        <h1>Impressum</h1>
                    </header>
                    <p>Herausgeber dieser Webseiten ist die Firma:</p>
                    <p>NEA X GmbH</p>
                    <p>Am Kraftversorgungsturm 5<br />52070 Aachen<br /><br />Amtsgericht Aachen, HRB 21202<br />Gesch&auml;ftsf&uuml;hrer: Dr. Patrick Beaujean / Jens Meyer-Beeck<br />Umsatzsteuer-ID-Nr.: DE312126077</p>
                    <p>Alle unter dieser Adresse erreichbaren Internetseiten sowie deren Textinhalte und Bildmaterialien sind urheberrechtlich gesch&uuml;tzt. Vervielf&auml;ltigung jeglicher Art auf Digitalen- oder Printmedien bedarf der schriftlichen Genehmigung des Herausgebers und Urhebers.</p>
                    <p><u>Verantwortlich f&uuml;r den Inhalt:</u></p>
                    <p>Dr. Patrick Beaujean<br />Am Kraftversorgungsturm 5<br />52070 Aachen</p>
                    <p>Diese Website wurde mit gr&ouml;&szlig;tm&ouml;glicher Sorgfalt zusammengestellt. Trotzdem k&ouml;nnen wir die Fehler-freiheit und Genauigkeit der enthaltenen Informationen nicht garantieren. Sollten wir in Urheberrechte Dritter eingegriffen oder Sie Fragen haben, bitten wir um sofortige Benachrichtigung. Der Inhalt dieser Web-Site ist urheberrechtlich gesch&uuml;tzt. Die Bereitstellung im Internet gibt Ihnen dennoch das Recht, das bereitgestellte Material zu privaten Zwecken zu speichern und zu vervielf&auml;ltigen. Kommerzielle Nut-zung zur Weiterver&ouml;ffentlichung oder kostenlosen Verteilung nur in Absprache mit der NEA X GmbH. Auch diese Hinweise unterliegen der Aktualisierung.</p>
                    <p><h4><u>Haftung</u></h4></p>
                    <p>Die NEA X GmbH bem&uuml;ht sich, auf dieser Web-Site nur vollst&auml;ndige und richtige Informationen zu ver&ouml;f-fentlichen, kann aber keine Haftung oder Garantie f&uuml;r Aktualit&auml;t, Richtigkeit und Vollst&auml;ndigkeit der Informationen &uuml;bernehmen. Diese Site ist st&auml;ndig Ver&auml;nderungen und Erg&auml;nzungen unterworfen, die der Aktualisierung und Vervollst&auml;ndigung der angebotenen Informationen und Funktionen dienen. Der Herausgeber der Site, die NEA X GmbH, haftet nicht f&uuml;r direkte oder indirekte Sch&auml;den, einschlie&szlig;lich entgangenen Gewinns, die mit der Nutzung des Informations- und Funktionsangebots dieser Site in Ver-bindung gebracht werden k&ouml;nnen. Dies gilt insbesondere auch f&uuml;r die ausgewiesenen Links (Verbindun-gen) zu anderen Sites, auf die NEA X GmbH keinen Einfluss hat und f&uuml;r deren Inhalt keine Verantwor-tung &uuml;bernommen werden kann.</p>
                    <p>Einige der gezeigten Bilder durch Pixabay (pixabay.com) und Pexels (pexels.com), diese sind gemeinfrei (Public Domain) entsprechend der Verzichtserkl&auml;rung Creative Commons CC0.&nbsp;</p>
                    <p>Urheberrecht: (c) Copyright 2017 &ndash; NEA X GmbH&nbsp;</p>
                </div>
            </section>
        </div>
    </Layout>
)

export default Imprint